import React from "react";
import { RequestedImage } from "../../../redux/types/defaultTypes";
import styles from "./itemNews.module.sass";

interface propsForItemNews {
  title: string;
  sub_title: string;
  paragraph: string;
  type: string;
  date: string;
  img?: RequestedImage;
  id: number;
  // clicked:()=>{}
}
const itemNews = (props: propsForItemNews) => {
  return (
    <div
      // onClick={props.clicked}
      className={styles.itemNewsContainer}
    >
      <div className={styles.gridForItemNews}>
        <div className={styles.contentItemNewsContainer}>
          <div className={styles.titleForItemNewsContainer}>
            <h3 className={styles.leftTitleForItemNews}>{props.type}</h3>
            <h3 className={styles.rightTitleForItemNews}>
              <i>{props.date}</i>
            </h3>
          </div>
          <div className={styles.imgForItemNews}>
            <img
              src={`${process.env.REACT_APP_BASE_URL}${props.img?.url}`}
              alt=""
            />
          </div>
          <div>
            <h2 className={styles.titleForImgForItemNews}>{props.title}</h2>
            <div className={styles.paragraphForImgForItemNews}>
              {/* <ReactMarkdown source={`${props.paragraph.substring(0,250)}....`}/> */}
              <p>{`${props.paragraph.substring(0, 200)}....`}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default itemNews;

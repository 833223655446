import React,{useState} from "react";
import {useForm,Controller} from 'react-hook-form';
import { Grid } from "@material-ui/core";
import styles from './socialCalenderForm.module.scss';
import axios from "axios";
import PhoneInputWithCountry,{isValidPhoneNumber} from "react-phone-number-input"

type SocialCalender={
    name:string;
    phone:string;
    email:string;
    companyName:string;
    jobTitle:string;
  }
const SocialCalenderForm=()=>{
    const [isChecked, setIsChecked] = useState(true);
    const { register, handleSubmit, errors,control } = useForm<SocialCalender>(); // initialize the hook
    const onSubmit = (data:SocialCalender) => {
        axios.post("https://portal.tacverse.com/api/applicants",data)
      .then((response)=>{
          // console.log(response)
          // console.log(formData)
          // console.log(data)
        //   history.push("/thankYou");
      })
      .catch((err)=>{})
    }
    return(
        <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off" className={styles.formForApplyHere}>
          
            <Grid item md={12} xs={12}>
            <label htmlFor="name">Full Name:*</label>
            <input type="text" ref={register ({required:true})} name="name" placeholder="Enter your name..." />
            {errors.name && errors.name.type === "required" && (
          <div className={styles.error}>Your must enter your name</div>)}
            </Grid>
            <Grid item md={12} xs={12}>
            <label htmlFor="phone">Phone Number:*</label>
            <Controller control={control} rules={{validate:value=>isValidPhoneNumber(`${value}`),required: true }} 
                    name="phone"
                    render={({onChange,value})=>(
                      <>
                        <PhoneInputWithCountry
                        className={styles.phone}
                        name="phone"
                        defaultCountry="EG"
                        value={value}
                        onChange={onChange}
                          // error={valuePhone ? (isValidPhoneNumber(valuePhone) ? true : false) : 'Phone number required'}
                        />
                        </>
                      )
                    }
                    
                    />
             </Grid>
              <Grid item md={12} xs={12}>
              <label htmlFor="email">E-Mail:*</label>
          <input type="email" name="email" ref={register({required:true,pattern: /[a-z]*(\.|_)?([a-z0-9]+(\.|_)?)+?[a-z0-9]+@[a-z0-9]+_?[a-z0-9]+\.[a-z]+/g,})} placeholder="Enter your email..." />
          {errors.email && errors.email.type === "required" && (
          <div className={styles.error}>Your must enter your email</div>)}
          {errors.email && errors.email.type === "pattern" && (
										 <div className={styles.error}>This email is wrong</div>
										)}
          </Grid>
          <Grid item md={12} xs={12}>
              <label htmlFor="company">Company Name:</label>
          <input type="company" name="company" ref={register ({required:true})} placeholder="Enter your email..." />
          {errors.email && errors.email.type === "required" && (
          <div className={styles.error}>Your must enter your email</div>)}
          {errors.email && errors.email.type === "pattern" && (
										 <div className={styles.error}>This email is wrong</div>
										)}
          </Grid>
         
          <Grid item md={12} xs={12}>
              <label htmlFor="company">Job Title:</label>
          <input type="company" name="company" ref={register ({required:true})} placeholder="Enter your email..." />
          {errors.email && errors.email.type === "required" && (
          <div className={styles.error}>Your must enter your email</div>)}
          {errors.email && errors.email.type === "pattern" && (
										 <div className={styles.error}>This email is wrong</div>
										)}
          </Grid>
          <label className={styles.checkboxContainer}>
                      <input
                        type="checkbox"
                        checked
                        onChange={() => {
                          setIsChecked(!isChecked);
                        }}
                      />
                      <svg
                        className={`${styles.checkbox} ${isChecked} ? ${styles["checkbox--active"]} : ""
                        }`}
                        aria-hidden="true"
                        viewBox="0 0 15 11"
                        fill="none"
                      >
                        <path
                          d="M1 4.5L5 9L14 1"
                          strokeWidth="2"
                          stroke={isChecked ? "#fff" : "none"}
                        />
                      </svg>
                      Check to Sign Up for our Newsletter
                    </label>
                  
        
          <Grid item md={12} xs={12} className={styles.btnSubmit}>
          <button type="submit" > SUBMIT</button>
          </Grid>
          

       
   


  </form>

    )
}
export default SocialCalenderForm;
import React, { useState } from "react";
import { Container, Grid } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faBehance,
  faPinterestP,
  faLinkedinIn,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
  // faPaperclip,
} from "@fortawesome/free-solid-svg-icons";
// import arrow from "../../imgs/arrow-down-to-bracket.png";
import {  Controller, useForm } from "react-hook-form";
import styles from "./form.module.sass";
import { useHistory } from "react-router-dom";
import arrowTop from "../../imgs/arrow-top-to-bracket.png";
import axios from "axios";
import PhoneInputWithCountry,{isValidPhoneNumber} from "react-phone-number-input"

type ContactUsForm = {
  name: string;
  ref: string;
  type: string;
  phone: any;
  email: string;
  experience: string;
  cv: any;
};

const Form = (props: any) => {
  const [isChecked, setIsChecked] = useState(true);
  const [fileUploadState, setFileUpload] = useState("");
  const fileUpload = (e: any) => {
    e.persist();
    let ext = e.target?.files[0]?.name?.match(/\.([^.]+)$/)[1];
    switch (ext) {
      case "jpg":
      case "svg":
      case "png":
      case "tif":
      case "pdf":
      case "doc":
        setFileUpload(e.target?.files[0]?.name);
        break;
      default:
        alert("Not allowed");
    }
  };
  let history = useHistory();
  // const sectionContainer = useRef<HTMLDivElement>(null);
  const { control
    ,register, handleSubmit, errors } = useForm<ContactUsForm>(); // initialize the hook

  const onSubmit = (data: any) => {
    if (props.button === "download") {
      // let phone=valuePhone.toString()
      // console.log(isValidPhoneNumber(valuePhone),valuePhone)
      
    //  if( isValidPhoneNumber(valuePhone)){
      // setIsvalidPhone(false)
      axios
      .post("https://portal.tacverse.com/api/contactus", data)
      .then((response) => {
        // console.log(response)
        if (isChecked) {
          setTimeout(() => {
            window.open(
              "https://api.theappconcept.com/uploads/tac-universe-company-profile-2020.pdf",
              "_blank"
            );
        })
          
        }
        history.push("/thankYou");
        // console.log(data)
      })
      .catch((error) => {
        // console.log(error.response.data)
        // console.log(data)
      });
    //  }
    //  else{
    //   setIsvalidPhone(true)
    //  }
      

      // console.log(data);
    } else {
      const formData = new FormData();
      // console.log(data);
      //  Object.keys(Data).forEach((key)=>{
      formData.append("cv", data.cv[0]);
      formData.append("email", data.email);
      formData.append("name", data.name);
      formData.append("phone", data.phone);
      formData.append("experience", data.experience);
      formData.append("description", data.description);
      formData.append("job_id", data.ref);

      //  })
      axios
        .post("https://portal.tacverse.com/api/applicants", formData)
        .then((response) => {
          history.push("/thankYou");
        })
        .catch((err) => {});
    }
  };

  return (
    <Grid
      container
      className={styles.container}
      style={{ height: "100vh", overflowX: "hidden" }}
    >
      <Grid
        item
        md={4}
        xs={12}
        className={styles.Ybg}
        style={{
          backgroundImage: `url(${props.img}), linear-gradient(180deg, rgba(255,241,0,1) 0%, rgba(255,241,10,1) 50%, rgba(255,215,0,1) 100%)`,
        }}
      >
        <Container>
          <Grid container justify="center">
            <Grid item md={10}>
              <div className={styles.title}>
                <h2>
                  {props.title} <br />
                  <span>{props.subTitle} </span>
                </h2>
              </div>
              <ul className={styles.contactListIcon}>
                <li>
                  {" "}
                  <FontAwesomeIcon icon={faPhone} />
                  <span>+20-122-666-9843</span>{" "}
                </li>
                <li>
                  {" "}
                  <FontAwesomeIcon icon={faEnvelope} />
                  <span>info@tacuniverse.com</span>{" "}
                </li>{" "}
                <li style={{ display: "flex" }}>
                  {" "}
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  <span>
                    1, Kafr Abou Street, Kafr Abdou, Alexandria, Egypt
                    <br /> <br />
                    9, Street 9, Maadi, Cairo, Egypt
                  </span>
                </li>
              </ul>
              <div className={styles.socialIcon}>
                {" "}
                <p>FIND US ON</p>
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/theappconcept"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.behance.net/tacuniverse"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faBehance} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.pinterest.com/tacuniverse/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faPinterestP} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/tacuniverse/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/tacuniverse"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/tac.universe/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid item md={8} xs={12} className={styles.Bbg}>
        <Container>
          <form
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            autoComplete="off"
            className={styles.formForContactUs}
          >
            <Grid container>
              <Grid item md={6} xs={12} className={styles.gridContainer}>
                <label htmlFor="name">Full Name:*</label>{" "}
                <input
                  className={styles.input}
                  id="name"
                  type="text"
                  ref={register({ required: true })}
                  name="name"
                  placeholder="Enter your name..."
                />
                {errors.name && errors.name.type === "required" && (
                  <div className={styles.error}>This field is required</div>
                )}
              </Grid>
              <Grid item md={6} xs={12} className={styles.gridContainer}>
                <label htmlFor="phone">Phone Number:*</label>

                <Grid container>
                  <Grid item md={12} xs={12}>
                    <Controller control={control} rules={{validate:value=>isValidPhoneNumber(`${value}`),required: true }} 
                    name="phone"
                    render={({onChange,value})=>(
                      <>
                        <PhoneInputWithCountry
                        name="phone"
                        defaultCountry="EG"
                        value={value}
                        onChange={onChange}
                          // error={valuePhone ? (isValidPhoneNumber(valuePhone) ? true : false) : 'Phone number required'}
                        />
                        </>
                      )
                    }
                    
                    />
          
                    {/* {" "}
                    <select name="code" className={styles.input}>
                      <option value="+20">+20</option>
                    </select> */}
                  </Grid>
                  {/* <Grid item md={10}>
                    {" "}
                    <input
                      className={styles.input}
                      id="phone"
                      type="phone"
                      ref={register({ required: true })}
                      name="phone"
                      placeholder="Enter your number..."
                      style={{ paddingLeft: "10px", position: "relative" }}
                    />
                  </Grid> */}
                </Grid>
                { errors.phone&&errors.phone.type === "required"&& (
                  <div className={styles.error}>This field is required</div>
                )}
                {errors.phone &&(<div className={`${styles.error} ${styles.secondError}`}>Please enter a valid phone number</div>)}

                {/* {validPhone&&(
                  <div className={styles.error}>This field is required</div>
                )} */}
              </Grid>
              <Grid item md={6} xs={12} className={styles.gridContainer}>
                <label htmlFor="email">Email Address:*</label>
                <input
                  className={styles.input}
                  id="email"
                  type="email"
                  name="email"
                  ref={register({ required: true })}
                  placeholder="Enter your email..."
                />
                {errors.email && errors.email.type === "required" && (
                  <div className={styles.error}>This field is required</div>
                )}
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                className={styles.gridContainer}
                style={{
                  alignSelf: "end",
                }}
              >
                <select
                  className={styles.input}
                  name="ref"
                  ref={register({ required: true })}
                  defaultValue=""
                >
                  <option value="" disabled>
                    {props.selectText}
                  </option>
                  {props.valuesForSelectBox.map((option: any) => {
                    return (
                      <option key={option.id} value={option.id}>
                        {option.title}
                      </option>
                    );
                  })}
                </select>
                {errors.ref && errors.ref.type === "required" && (
                  <div className={styles.error}>This field is required</div>
                )}
              </Grid>
              <Grid item md={6} xs={12} className={styles.gridContainer}>
                {/* <input type="text" ref={register} name="help" placeholder="What can we help you with?" /> */}
                {props.button === "download" ? (
                  <>
                    <select
                      className={styles.input}
                      name="type"
                      ref={register({ required: true })}
                      defaultValue=""
                    >
                      <option value="" disabled>
                        What can we help you with?
                      </option>
                      <option value="Branding & Design">
                        Branding & Design
                      </option>
                      <option value="Photography & Vidography">
                        Photography & Vidography
                      </option>
                      <option value="Creative Concept">Creative Concept</option>
                      <option value="UI UX">UI UX</option>
                      <option value="Web Design & Development">
                        Web Design & Development
                      </option>
                      <option value="Mobile App Development">
                        Mobile App Development
                      </option>
                      <option value="Social Media Management">
                        Social Media Management
                      </option>
                      <option value="Community Management">
                        Community Management
                      </option>
                      <option value="Digital Media Buying">
                        Digital Media Buying
                      </option>
                      <option value="Digital Strategy">Digital Strategy</option>
                    </select>
                    {errors.type && errors.type.type === "required" && (
                      <div className={styles.error}>This field is required</div>
                    )}
                  </>
                ) : (
                  <>
                    <label htmlFor="email">
                      How many years of experience do you have?*
                    </label>
                    <input
                      className={styles.input}
                      type="number"
                      ref={register({ required: true })}
                      name="experience"
                    />
                    {errors.experience &&
                      errors.experience.type === "required" && (
                        <div className={styles.error}>
                          This field is required
                        </div>
                      )}
                  </>
                )}
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
                className={`${styles.btnSubmit} ${styles.gridContainer}`}
              >
                {/* <label htmlFor="companyProfile">
                  {props.button !== "download" && (
                    <FontAwesomeIcon
                      icon={faPaperclip}
                      style={{
                        marginRight: `${
                          props.button !== "download" && "10px"
                        } `,
                      }}
                    />
                  )}
                  {props.textFile}
                </label> */}
                {props.button === "download" ? (
                  <>
                    <label className={styles.checkboxContainer}>
                      <input
                        type="checkbox"
                        checked
                        onChange={() => {
                          setIsChecked(!isChecked);
                        }}
                      />
                      <svg
                        className={`${styles.checkbox} ${isChecked} ? ${styles["checkbox--active"]} : ""
                        }`}
                        aria-hidden="true"
                        viewBox="0 0 15 11"
                        fill="none"
                      >
                        <path
                          d="M1 4.5L5 9L14 1"
                          strokeWidth="2"
                          stroke={isChecked ? "#fff" : "none"}
                        />
                      </svg>
                      Keep checked for downloading our company profile once the
                      form is submitted.
                      {/* <span className={styles.checkmark}></span> */}
                    </label>
                  </>
                ) : (
                  // <button>
                  //   <a
                  //     href="https://api.theappconcept.com/uploads/tac-universe-company-profile-2020.pdf"
                  //     target="_blank"
                  //     rel="noreferrer"
                  //   >
                  //     Download{" "}
                  //   </a>
                  //   <img
                  //     src={arrow}
                  //     alt="arrow"
                  //     style={{ width: "23px", display: "inline-block" }}
                  //   />
                  // </button>
                  <div className={styles.fileUpload}>
                    <label htmlFor="file-upload">
                      Upload
                      <img
                        src={arrowTop}
                        alt="arrow"
                        style={{
                          width: "23px",
                          display: "inline-block",
                          marginLeft: "10px",
                        }}
                      />{" "}
                    </label>
                    <input
                      id="file-upload"
                      ref={register({ required: true })}
                      onChange={fileUpload}
                      type="file"
                      accept="image/* , .pdf, .doc"
                      name="cv"
                    />
                    <span> {fileUploadState}</span>
                    {errors.cv && errors.cv.type === "required" && (
                      <div className={styles.error}>This field is required</div>
                    )}
                  </div>
                )}
              </Grid>
              <Grid item md={12} xs={12} className={styles.gridContainer}>
                <Grid item md={12}>
                  <label htmlFor="message">{props.message}</label>
                  <textarea
                    className={styles.input}
                    id="message"
                    name="description"
                    placeholder="Type your text..."
                    ref={register({ required: true })}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                className={`${styles.btnSubmit} ${styles.gridContainer}`}
              >
                <button type="submit"> SUBMIT</button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Grid>
    </Grid>
  );
};
export default Form;

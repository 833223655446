import { Grid } from "@material-ui/core";
import React from "react";
import styles from './socialCalender.module.scss';
import calender from '../../imgs/calender-bg.png';
import SocialCalenderForm from "./socialCalenderForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faBehance,
  faPinterestP,
  faLinkedinIn,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
const SocialCalender=()=>{
return(
    
    <div className={styles.conatiner}>
        
    <Grid container justify="center">
        <Grid item md={4} xs={10} className={styles.yellow}>
<div>
    <h2>Checkout 
Our Calendar !</h2>
<div><img src={calender} alt="" /></div>
</div>
        </Grid>
        <Grid item md={4} xs={12} className={styles.black}>
           <div className={styles.blackContainer}>
           <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/theappconcept"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.behance.net/tacuniverse"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faBehance} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.pinterest.com/tacuniverse/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faPinterestP} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/tacuniverse/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/tacuniverse"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/tac.universe/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                </ul>
           <h3>Social Media Calendar <span>Fill up the form to download TAC Calendar 2022.</span> </h3>
           <Grid item md={8} xs={10}>
           <SocialCalenderForm/>
           </Grid>
               </div> 
        </Grid>
    </Grid>
    </div>
  
)
}
export default SocialCalender;
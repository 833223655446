import React, { useRef } from "react";
import ReactDom from "react-dom";
const portalDiv = document.getElementById("portal");

export const Modal: React.FC<{
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setShowModal }) => {
  // close the modal when clicking outside the modal.
  const modalRef = useRef<HTMLDivElement>(null);
  const closeModal = (e: React.MouseEvent) => {
    console.log(e);
    if (e.target === modalRef.current) {
      setShowModal(false);
      console.log("close2");
    }
  };

  // render the modal JSX in the portal div.
  return portalDiv
    ? ReactDom.createPortal(
        <div className="container" ref={modalRef} onClick={closeModal}>
          <div className="modal">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/Cx0WdU20PSM"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <button onClick={() => setShowModal(false)}>X</button>
          </div>
        </div>,
        portalDiv
      )
    : null;
};

import React, { useCallback, useEffect, useState } from "react";
// import Landing from "./components/landing/landing";
import Home from "./components/home/home";
import About from "./components/about/about";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import human from "./imgs/human.png";
import chair from "./imgs/chair.png";
import { Route, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getHomeData } from "./redux/actions/home";
import { isLoading } from "./redux/actions/shared";
import { AppState } from "./redux/reducers/index";
import Work from "./components/caseStudies/Work";
import Services from "./components/services/services";
import News from "./components/news/news";
// import Careers from "./components/careers/careers";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Form from "./components/form/form";
import ThankYou from "./components/thankYou/thankYou";
import { getAboutData } from "./redux/actions/about";
import { getCaseStudyData } from "./redux/actions/caseStudy";
import { getNewsData } from "./redux/actions/news";
import { getServicesData } from "./redux/actions/services";
import { getDominosData } from "./redux/actions/dominos";
import Dominos from "./components/dominos/dominos";
import Dove from "./components/dove/dove";
import Cairokee from "./components/cairokee/cairokee";
import { INewsSection } from "./redux/types";
import NewsInner from "./components/news/newsInner/newsInner";
import EidInner from "./components/news/newsInner/eidInner";
import { getJobs } from "./redux/actions/jobs";
import SocialCalender from "./components/socialCalender/socialCalender";
function App() {
  // const [textColor, setHeaderColor] = useState<string>("");
  const loading = useSelector((state: AppState) => state.isLoading);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [fixedHeaderPaths] = useState<string[]>(["/"]);
  const newsData: INewsSection[] = useSelector(
    (state: AppState) => state.newsData
  );
  const jobsData: any = useSelector((state: AppState) => state.jobsData);

  useEffect(() => {
    dispatch(isLoading(true));
    Promise.all([
      dispatch(getHomeData()),
      dispatch(getJobs()),
      dispatch(getAboutData()),
      dispatch(getCaseStudyData()),
      dispatch(getNewsData()),
      dispatch(getServicesData()),
      dispatch(getDominosData()),
    ]).finally(() => {
      dispatch(isLoading(false));
    });
    return () => {
      window.scroll(0, 0);
    };
  }, [dispatch]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [pathname]);
  const [widthAndLeft, setWidthAndLeft] = useState<{ w: number; x: number }>({
    w: 0,
    x: 0,
  });
  const setWAndPHandler = useCallback((w: number, x: number) => {
    setWidthAndLeft((prev) => {
      return { w: w, x: x };
    });
  }, []);
  return (
    <div>
      {/* {loading ? <Landing /> : null} */}
      <Header widthAndLeft={widthAndLeft} fixedHeaderPaths={fixedHeaderPaths} />
      <main>
        {/* <div className="sticky-copyright">
					<p>
						powered by{" "}
						<a href="https://tacuniverse.com" target="_blank" rel="noopener noreferrer">
							TAC UNIVERSE
						</a>
					</p>
				</div> */}
        <Route exact path="/">
          {!loading && <Home setWAndP={setWAndPHandler} />}
        </Route>
        <Route exact path="/socialCalender">
          {!loading && <SocialCalender />}
        </Route>
        <Route exact path="/about">
          {!loading && <About />}
        </Route>
        <Route exact path="/work">
          {!loading && <Work />}
        </Route>
        <Route exact path="/dominos">
          {!loading && <Dominos />}
        </Route>
        <Route exact path="/dove">
          {!loading && <Dove />}
        </Route>
        <Route exact path="/cairokee">
          {!loading && <Cairokee />}
        </Route>
        <Route exact path="/services">
          {!loading && <Services />}
        </Route>
        <Route exact path="/news">
          {!loading && <News content={newsData[0]} />}
        </Route>
        <Route exact path="/new/Greetings-The-Egyptian-Way">
          {!loading && <EidInner />}
        </Route>
        <Route exact path="/new/:id">
          {!loading && <NewsInner content={newsData[0]} />}
        </Route>
        <Route exact path="/careers">
          {!loading && (
            <Form
              title="Searching for a Job ?"
              subTitle="Fill up the form and our team will get back to you soon."
              img={chair}
              selectText="What role are you applying for ?*"
              valuesForSelectBox={jobsData.data}
              textFile={`Attach Your CV / Portfolio *`}
              button="upload"
              message="Do you want to add anything?"
            />
          )}
        </Route>
        <Route exact path="/contactUs">
          {!loading && (
            <Form
              title="Contact Information"
              subTitle="Fill up the form and our team will get back to you soon."
              img={human}
              selectText="How did you hear about us?"
              valuesForSelectBox={[
                { title: "Search Engine", id: "Search Engine" },
                { title: "Social Network", id: "Social Network" },
                { title: "Advertisement", id: "Advertisement" },
                { title: "Email", id: "Email" },
                { title: "Event", id: "Event" },
                { title: "Referral", id: "" },
                { title: "Other", id: "Other" },
              ]}
              textFile="Download Our Company Profile"
              button="download"
              message="Leave us a message:"
            />
          )}
        </Route>
        <Route exact path="/thankYou">
          {!loading && <ThankYou />}
        </Route>
      </main>
      {pathname !== "/contactUs" &&
      pathname !== "/socialCalender"&&
      pathname !== "/thankYou" &&
      pathname !== "/careers" ? (
        <Footer />
      ) : null}
    </div>
  );
}

export default App;
